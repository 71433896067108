<template>
  <div data-layout-name="default">
    <div id="wrapper-header">
      <div id="wrapper-banner" :class="{ 'visible': shouldDisplayBreakingNewsBanner }">
        <BreakingNewsBanner />
      </div>

      <TheHeader />
    </div>

    <main>
      <slot />
    </main>

    <TheFooter />
  </div>

  <ClientOnly>
    <Teleport to="#overlay">
      <transition name="fade" mode="out-in">
        <TheSearch v-if="isSearchVisible" @close="closeSearch" />
      </transition>

      <transition name="slide-from-right">
        <SidebarMobile v-if="isMobileMenuVisible" @close="closeMobileMenu" />
      </transition>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
// components
import TheHeader from '~/components/redesign/TheHeader.vue';
import SidebarMobile from '~/components/redesign/SidebarMobile.vue';
import TheSearch from '~/components/redesign/TheSearch';
import BreakingNewsBanner from '~/components/redesign/BreakingNewsBanner.vue';
import TheFooter from '~/components/redesign/TheFooter.vue';

// composables
import { storeToRefs } from 'pinia';
import onRouteChange from '~/composables/onRouteChange';

// store
import useUIStore from '~/stores/ui';

const uiStore = useUIStore();
const { setOverlay, setMobileMenuVisibility, setSearchVisibility } = uiStore;

const {
  isMobileMenuVisible,
  isSearchVisible,
  shouldDisplayBreakingNewsBanner,
} = storeToRefs(uiStore);

const closeMobileMenu = () => {
  setOverlay({ visible: false });
  setMobileMenuVisibility(false);
};

const closeSearch = () => {
  setOverlay({ visible: false });
  setSearchVisibility(false);
};

onRouteChange(closeMobileMenu);
</script>

<style lang="scss">
@use "_/mixins/media";
@use "_/mixins/size";
@use "_/transitions";
@use "_/fn";

[data-layout-name="default"] {
  #wrapper-header {
    position: sticky;
    top: 0;

    z-index: 6;

    #wrapper-banner {
      overflow: hidden;
      transition: all 0.5s ease;
      max-height: 0;

      &.visible {
        max-height: 5rem;

        [data-component-name="BreakingNewsBanner"] {
          transform: translateY(0);
        }
      }

      [data-component-name="BreakingNewsBanner"] {
        transform: translateY(-100%);
        transition: all 0.4s ease;
      }
    }
  }
}

[data-component-name="SidebarMobile"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

[data-component-name="TheSearch"] {
  position: absolute;
  top: 5.5rem;

  @include size.fluid-max-width(75rem);
  max-height: calc(100dvh - (5.5rem + 1.5rem));

  @include media.tablet {
    @include size.fluid-max-width(calc(100% - 4rem));
  }

  @include media.mobile {
    top: 3.5rem;

    @include size.fluid-max-width(100%);
    max-height: calc(100dvh - (3.5rem + 1rem));
  }
}
</style>
