<script lang="ts" setup>
import Buttons from '~/components/redesign/Buttons';

// store
import useUIStore from '~/stores/ui';

// utils
import get from 'lodash.get';
import { sleep, isString } from '~/utils';

// constants
const DEFAULT_BG_PATH = '/redesign/images/bn-banner/bg.webp';

const { locale } = useI18n();

const uiStore = useUIStore();
const { setBannerDataLoaded, closeBanner } = uiStore;

const { data, status } = await useAsyncData(
  'banner-data',
  () => GqlBreakingNewsBannerSettings(),
  {
    transform: response => response.site_setting?.value,
    server: false,
  },
);

watch(locale, () => refreshNuxtData('banner-data'));

watch(status, async statusValue => {
  if (statusValue === 'success') {
    await sleep(2500);

    setBannerDataLoaded(true);
  }
});

const bgUrl = computed(
  () => `url(${get(data.value, 'banner_bg_url', DEFAULT_BG_PATH)})`,
);
const btnLabel = computed(() => get(data.value, 'banner_button_label'));
const link = computed(() => get(data.value, 'banner_link_url'));
const logoMobileSrc = computed(() => get(data.value, 'banner_logo_mobile_url'));
const logoSrc = computed(() => get(data.value, 'banner_logo_url'));
const text = computed(() => get(data.value, 'banner_text'));

const { width: screenWidth } = useWindowSize();
const isDesktop = computed(() => screenWidth.value > 1216);

const size = computed(() => (isDesktop.value ? 'medium' : 'small'));

const handleCloseClick = () => {
  setBannerDataLoaded(false);
  closeBanner();
};

type LogoTypes = 'desktop' | 'mobile';
type LogoStates = Record<'canLoad' | 'loaded' | 'broken', boolean>;

const logosStates = reactive<Record<LogoTypes, LogoStates>>({
  desktop: {
    canLoad: true,
    loaded: false,
    broken: false,
  },
  mobile: {
    canLoad: true,
    loaded: false,
    broken: false,
  },
});

const setLogoState = <
  N extends LogoTypes,
  S extends keyof LogoStates,
  V extends LogoStates[S]
>(
  logoType: N,
  state: S,
  value: V,
) => {
  logosStates[logoType][state] = value;
};

const successHandler = (logoType: LogoTypes) => {
  setLogoState(logoType, 'loaded', true);
  setLogoState(logoType, 'broken', false);
};
const errorHandler = (logoType: LogoTypes) => {
  setLogoState(logoType, 'broken', true);
};

watch([logoSrc, logoMobileSrc], ([logoSrcValue, logoMobileSrcValue]) => {
    setLogoState('desktop', 'canLoad', isString(logoSrcValue));
    setLogoState('mobile', 'canLoad', isString(logoMobileSrcValue));
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div data-component-name="BreakingNewsBanner">
    <div class="left" />

    <a
      :href="link"
      class="clickable-container"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div class="logo">
        <NuxtImg
          v-if="logosStates.desktop.canLoad"
          :src="logoSrc"

          :class="[
            'desktop',
            {
              'image-loaded': logosStates.desktop.loaded,
              'image-broken': logosStates.desktop.broken
            }
          ]"
          densities="1x 2x"

          @load="successHandler('desktop')"
          @error="errorHandler('desktop')"
        />

        <NuxtImg
          v-if="logosStates.mobile.canLoad"
          :src="logoMobileSrc"

          :class="[
            'mobile',
            {
              'image-loaded': logosStates.mobile.loaded,
              'image-broken': logosStates.mobile.broken,
            }
          ]"
          densities="1x 2x"

          @load="successHandler('mobile')"
          @error="errorHandler('mobile')"
        />
      </div>

      <p>{{ text }}</p>

      <Buttons.Link
        :to="link"
        :size
        external
        target="_blank"
        fill="none"
        accent="white"
      >
        {{ btnLabel }}

        <SvgIcon name="arrow-forward" fill="inherit" />
      </Buttons.Link>
    </a>

    <Buttons.Regular
      fill="none"
      accent="white"
      size="small"
      @click="handleCloseClick"
    >
      <SvgIcon name="close" fill="inherit" />
    </Buttons.Regular>
  </div>
</template>

<style scoped lang="scss">
@use "_/mixins/flex";
@use "_/mixins/typo";
@use "_/mixins/media";
@use "_/mixins/size";
@use "_/fn";

[data-component-name="BreakingNewsBanner"] {
  @include media.tablet {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    z-index: 25;
  }

  min-height: 5rem;
  padding: 0 1.5rem;

  background: fn.token("outline-action-secondary") v-bind(bgUrl) no-repeat
    center / cover;

  @include flex.center-sb;

  @include media.tablet {
    @include flex.center-sb;
    padding: 0;

    min-height: 6.25rem;
  }

  @include media.mobile {
    @include flex.start-sb;
    min-height: 8rem;
    padding: 0;
  }

  .left {
    width: 1.75rem;

    @include media.mobile {
      width: 2rem;
    }
  }

  .clickable-container {
    @include flex.center-center;
    gap: 1.5rem;

    padding: 1rem 1.5rem;

    @include media.tablet {
      @include flex.center-sb;
      max-width: calc(100% - 2rem);
      width: 100%;
    }

    @include media.mobile {
      flex-direction: column;
      gap: 0;

      padding: 0;
    }

    .logo {
      pointer-events: none;

      @include size.fixed-width(12.5rem);
      @include size.fixed-height(2.5rem);

      @include media.mobile {
        @include size.fixed-width(8.75rem);
        @include size.fixed-height(1.75rem);

        &:not(:has(img)) {
          @include size.fixed-height(0.5rem);
        }

        margin-top: 0.5rem;
      }

      .desktop {
        @include media.mobile {
          display: none;
        }
      }

      .mobile {
        display: none;

        @include media.mobile {
          display: block;
        }
      }

      img {
        max-width: 100%;
        height: auto;

        &.image-loaded {
          opacity: 1;
        }

        &.image-broken {
          opacity: 0;
        }
      }
    }

    p {
      @include typo.body-2;
      color: fn.token("text-reversed");

      word-break: break-word;

      @include media.tablet {
        text-align: center;
      }

      @include media.mobile {
        @include typo.body-4;
        padding: 0.75rem;
      }
    }

    :deep([data-component-name="Buttons.Base"]) {
      span {
        text-wrap: nowrap;
      }

      svg {
        transition: transform 0.3s ease-out;

        @include media.tablet {
          @include size.fixed(1.5rem);
        }
      }

      &:hover {
        svg {
          transform: translateX(0.3125rem);
        }
      }
    }
  }

  [data-component-name="Buttons.Regular"] {
    @include media.tablet {
      align-self: flex-start;

      :deep(svg) {
        @include size.fixed(1.5rem);
      }
    }

    @include media.mobile {
      @include size.fixed(1.75rem);
    }
  }
}
</style>
